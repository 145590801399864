import React from 'react';

import {
  useField,
  useFormikContext,
} from 'formik';

import { TextField } from '@mui/material';

const SelectFormUI = (
  {name,
  // options,
  ...props}) => { 

  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  
  const handleChange = e => {
    const { value } = e.target;
    setFieldValue(name, value);
  };

  const configSelect = {
    ...field, 
    ...props,
    select: true,
    variant: 'standard',
    fullWidth: true,
    onChange: handleChange
  };

  if (meta && meta.error && meta.touched) { 
    configSelect.error = true;
    configSelect.helperText= meta.error;
  }
  
  return (
    <TextField {...configSelect}>
      {/* {Object.keys(options).map((item,pos) =>{ 
        return(
          <MenuItem key={pos} value={item}>
            { options[item]}
          </MenuItem>
        )
      })} */}
    </TextField>
  )

}

export default SelectFormUI