import React from 'react'
import { useNavigate } from "react-router-dom";

import AssignmentIcon from "@mui/icons-material/Assignment";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import { Grid } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Box from "@mui/material/Box";

const NavBar = () => {
  let navigate = useNavigate();
  return (
    <Grid>
      <Box sx={{ mx: "auto", width: 500, marginTop: "3rem" }}>
        <BottomNavigation
          showLabels
        >
          <BottomNavigationAction
            className="animate__animated animate__slideInUp"
            label="Lista"
            icon={<PersonSearchIcon />}
            onClick={() => navigate("/search")}
          />
          <BottomNavigationAction
            className="animate__animated animate__slideInUp"
            label="Formulario"
            icon={<AssignmentIcon />}
            onClick={() => navigate("/form")}
          />
          
        </BottomNavigation>
      </Box>
    </Grid>
  );
};

export default NavBar;
