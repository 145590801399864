import * as React from 'react';
import { useState } from 'react';

import { Formik } from 'formik';

import { styled } from '@mui/material/styles';

import BasicTable from '../components/Table/Table';
import NavBar from '../components/Menu/navBar';

const Buscar = () => {
  const INITIAL_FORM_STATE = {
    search: '',
  }

  /*
   *****************************
      STYLED
   *****************************
  */
  const Contenedor = styled("div")(() => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: "2% 5%",
    width: "90%",
  }));

  // const Search = styled('div')(({ theme }) => ({
  //   position: 'relative',
  //   margin: '2rem',
  //   borderRadius: theme.shape.borderRadius,
  //   backgroundColor: alpha(theme.palette.common.white, 0.15),
  //   '&:hover': {
  //     backgroundColor: alpha(theme.palette.common.white, 0.25),
  //   },
  //   marginLeft: 0,
  //   [theme.breakpoints.up('sm')]: {
  //     marginLeft: theme.spacing(1),
  //     width: 'auto',
  //   },
  // }));

  // const SearchIconWrapper = styled('div')(({ theme }) => ({
  //   padding: theme.spacing(0, 2),
  //   height: '100%',
  //   position: 'absolute',
  //   pointerEvents: 'none',
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  // }));

  // const StyledInputBase = styled(InputBase)(({ theme }) => ({
  //   width: '100%',
  //   color: 'inherit',
  //   '& .MuiInputBase-input': {
  //     padding: theme.spacing(1, 1, 1, 0),
  //     // vertical padding + font size from searchIcon
  //     paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  //     transition: theme.transitions.create('width'),
  //     width: '100%',
  //     [theme.breakpoints.up('sm')]: {
  //       justifyContent: 'center',
  //       boxShadow: '1px 1px 5px 1px #ccc',
  //       width: '35ch',
  //       '&:focus': {
  //         width: '48ch',
  //       },
  //     },
  //   },
  // }));

  /*
  *****************************
    FIN DE STYLED
  *****************************
  */
  const [usuarios, setUsuario] = useState([]);

  function valor(values) { 
    setUsuario(values)
  }
  
  return (
    <Contenedor className="animate__animated animate__fadeIn">
      <NavBar></NavBar>
      <Formik
        initialValues={{ ...INITIAL_FORM_STATE }}
        onSubmit={(values) => {
          valor(values);
          return console.log(values);
        }}
      >
        {/* <Search>
          <TextFieldUI
            name="search"
            label="Nombre del piloto"
          />
          <br /> <br />
          < ButtonSearch/>
        </Search> */}
      </Formik>
      <BasicTable data={usuarios} />
    </Contenedor>
  );

}

export default Buscar