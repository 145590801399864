import * as React from 'react';
import {
  Route,
  Routes,
} from 'react-router-dom';
import RegistroPiloto from './auth/login';
import { UsersContext } from './context/usersContext';
import Buscar from './pages/Buscar';
import Credencial from './pages/Credencial';
import NotFoundPage from './pages/NotFoundPage';
import Login from './pages/RegistroPiloto';

function App() {
  
  return (
	  <UsersContext>
			<div>
		<Routes>
			<Route exact path="/" element={<Buscar />} />
			<Route path="/search" element={<Buscar />} />
			<Route path="/form" element={<Login />} />
			<Route path="/form/:id_form" element={<Login />} />
			<Route path="/login" element={<RegistroPiloto />} />
			<Route path="/credencial/:id_driver" element={<Credencial />} />
			<Route path="/404" component={<NotFoundPage />} />
			<Route path="*" component={<Buscar />} />
		</Routes>
		</div>
	  </UsersContext>
  );
}
export default App;
