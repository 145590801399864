import React from 'react';

import { useField } from 'formik';

import { TextField } from '@mui/material';

const TextFieldUI = ({ name,children, ...props }) => {

  const [field, meta] = useField(name)

  const configTextField = {
    ...field,
    ...props,
    fullWidth: true,
    variant: "standard"
  };

  if (meta && meta.touched && meta.error) { 
    configTextField.error = true;
    configTextField.helperText = meta.error
  }

  return (
    <TextField {...configTextField}
    >
      {children}
    </TextField>
  );
};

export default TextFieldUI

