import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  Avatar,
  Grid,
  Paper,
} from '@mui/material';

import FormLogin from '../components/Login/FormLogin';

const Login = () => {

  const paperStyle = { padding: '20px', height: '60vh', width: '25%', margin: "5% auto" }
  const avatarStyle = { backgroundColor: 'orange', margin: '20px 0' }

  return (
    <div className="login">
      <Grid>
        <Paper elevation={10} style={paperStyle}>
          <Grid align='center'>
            <Avatar style={avatarStyle}> <LockOutlinedIcon /> </Avatar>
            <h2>Sign up</h2>
            <br />
              <FormLogin/>
          </Grid>
        </Paper>
      </Grid>
    </div>
    )
}

export default Login