import {
  createContext,
  useContext,
  useState,
} from 'react';

const usersContext = createContext({});

export const useUsersContext = () => useContext(usersContext);

export function UsersContext({ children }) {
	const [users,setUsers] = useState([]);
	return(
		<usersContext.Provider value = {{
			users,
			setUsers
		}}>
			{children}
		</usersContext.Provider>
	)
}