import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

const User = {user: "admin",password:"admin"}

const validate = (values) => {
  const errors = {}
  
  if (values.user !== User.user) {
    errors.user = '*Usuario incorrecto  '
  }
  if (values.password !== User.password) {
    errors.password = '*Contraseña incorrecta  '
  }
  return errors
}

const FormLogin = () => {
  let navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      user: '',
      password: ''
    },
    validate,
    onSubmit: values => {
      navigate("/form");
      // console.log(values)
    }
  })

  
  const inputStyle = { width: '80%' }
  const buttonStyle = {
    width: '80%', backgroundColor: 'orange', margin: '20px 0'
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        name="user"
        style={inputStyle}
        label="Usuario *"
        variant="standard"
        placeholder="Ingrese su usuario"
        {...formik.getFieldProps('user')}
      />
      <br />
      <br />
      <TextField
        name="password"
        style={inputStyle}
        label="Contraseña *"
        variant="standard"
        placeholder="Ingrese su contraseña"
        type="password"
        {...formik.getFieldProps('password')}
      />
      <br /><br /><br />
      {formik.touched.user && formik.errors.password ? <div>
        {formik.errors.user} {formik.errors.password}</div> : null}      <br />
      <Button
        type="submit"
        style={buttonStyle}
        variant="contained">
        Ingresar
      </Button>
    </form>
)
}

export default FormLogin
