import './credencial.css';

import {
  useEffect,
  useState,
} from 'react';

import { useParams } from 'react-router-dom';

// import UseParams from 'react-router-dom';
import styled from '@emotion/styled';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import { usersApi } from '../api/userApi';

const Credencial = () => {
  let { id_driver } = useParams();

  let ruta_credencial = "https://codesociety.site/driver.codesociety.dev/credencial/" + id_driver;

  const Contenedor = styled.div`
    display: flex;
    justify-content: center;
  `;
  const Identificacion = styled.div`
    margin: 30px 0 50px 0;
    padding: 45px;
    width: 550px;
    height: 780px;
    display: flex;
    justify-content: center;
    align-content: center;
  `;
  const Contenido = styled.div`
    position: relative;
    border: 2px solid white;
    width: 100%;
    height: 100%;
    border-radius: 12px;
  `;

  const [user, setUser] = useState([]);

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const getUser = async () => {
    const request = await usersApi.get(
      "https://codesociety.site/driver.codesociety.dev/server/?list=" + id_driver
    );

    var data_final = request.data.data[0];
    console.log("data_final:", data_final);
    data_final.data_driver = JSON.parse(data_final.data_driver);
    setUser(data_final);
  };

  let default_img =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQYMa62z19XfXnODX6-HD_08TAO_Dj0USyRoh8ewedittk8iNv4P_rdMS4wQs3IdWRTDnM&usqp=CAU";

  return (
    <Contenedor className="animate__animated animate__fadeIn">
      <Identificacion className="fondo">
        <Contenido>
          <h1 className="title-credencial">{user?.data_driver?.name}</h1>
          <h1 className="title-credencial">{user?.data_driver?.lastname}</h1>
          <br />
          <section>
            <article className="datos-generales">
              <div className="foto">
                <img
                  className="img"
                  src={`${
                    user?.attachment
                      ? "../server/files/" + user?.attachment
                      : default_img
                  }`}
                  alt=""
                />
              </div>
              <div className="general">
                <div className="contactos">
                  <div className="redes">
                    <p>
                      <span>
                        <WhatsAppIcon className="whats" />
                      </span>
                      <a
                        href={"https://wa.me/" + user.data_driver?.celular}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {user.data_driver?.celular}
                      </a>
                    </p>
                  </div>
                  <div className="credencial">
                    <img
                      src={`https://codesociety.site/driver.codesociety.dev/server/?qr=${ruta_credencial}`}
                      alt=""
                    />
                  </div>
                </div>
                <div className="datos">
                  <div className="datos1">
                    <h3>DNI</h3>
                    <p>{user?.data_driver?.dni}</p>

                    <h3>Licencia N°</h3>
                    <p>{user?.data_driver?.nlicencia}</p>

                    <h3>Piloto N°</h3>
                    <p>{user?.data_driver?.npiloto}</p>

                    <h3>Modalidad</h3>
                    <p>{user?.data_driver?.modalidad}</p>
                  </div>
                  <div className="datos2">
                    <h3>Fecha de Nacimiento</h3>
                    <p>{user?.data_driver?.fechanac}</p>

                    <h3>Correo</h3>
                    <p>{user?.data_driver?.email}</p>

                    <h3>Licencia</h3>
                    <p>{user?.data_driver?.licencia}</p>
                  </div>
                </div>
              </div>
            </article>
            <article className="historial">
              <h2>Historial de Competiciones</h2>
              <div className="item-historial">
                <h3>
                  <br></br>
                </h3>
                <article>
                  <div className="item-informacion">
                    <h4>{user?.data_driver?.participacion}</h4>
                    {/* <h4>Lugar donde se realizo:</h4>
                    <h4>Fcha de Competencia:</h4>
                    <h4>Posicion Resultante:</h4> */}
                  </div>
                  {/* <div className="item-registro">
                    <p>Respuesta1</p>
                    <p>Respuesta2</p>
                    <p>Respuesta3</p>
                  </div> */}
                </article>
              </div>
              {/* <div className="item-historial">
                <h3>Nombre de competencia</h3>
                <article>
                  <div className="item-informacion">
                    <h4>Lugar donde se realizo:</h4>
                    <h4>Fcha de Competencia:</h4>
                    <h4>Posicion Resultante:</h4>
                  </div>
                  <div className="item-registro">
                    <p>Respuesta1</p>
                    <p>Respuesta2</p>
                    <p>Respuesta3</p>
                  </div>
                </article>
              </div> */}
            </article>
          </section>
        </Contenido>
      </Identificacion>
    </Contenedor>
  );
};

export default Credencial;
