import * as React from 'react';
import {
  useEffect,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import AssignmentIcon from '@mui/icons-material/Assignment';
import EditIcon from '@mui/icons-material/Edit';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { BottomNavigationAction } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { usersApi } from '../../api/userApi';

function BasicTable({ data }) {

  console.log('data:', data)
  
  let navigate = useNavigate();
  const [users, setUsers] = useState([]);
  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    const request = await usersApi.get('https://codesociety.site/driver.codesociety.dev/server/?list=all');
    console.log('request:', request)
    console.log('request.data.data:', request.data.data)
    var data_final = request.data.data;
    for (let index = 0; index < data_final.length; index++) {
      console.log('data_final[index].data_driver:', data_final[index].data_driver)
      data_final[index].data_driver = JSON.parse(data_final[index].data_driver);
    }
    setUsers(data_final);
  }

  // function Search (event) { 
  //   if (event.search == "") {
  //     return <div>HOLA</div>
  //   }
  //   else { 
  //     return <div>ESTERNOCLEIDOMASTOIDEO</div>
  //   }
  // }
  
  const rowTable = (user) => {
    if (user?.data_driver) { 
      return (
        <TableRow
          key={user.id_driver}
          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
          <TableCell align="center">
            <BottomNavigationAction className="animate__animated animate__slideInRight"
              label="QR"
              icon={<QrCodeScannerIcon fontSize="small" color="primary" />}
              onClick={() => {
                window.open(
                  `https://codesociety.site/driver.codesociety.dev/server/?qr=${"https://codesociety.site/driver.codesociety.dev/credencial/" + user.id_driver}`,
                  '_blank' // <- This is what makes it open in a new window.
                );
              }}
            />
          </TableCell>
          <TableCell align="center">
            <BottomNavigationAction className="animate__animated animate__slideInRight"
              label="Buscar"
              icon={<AssignmentIcon fontSize="small" color="primary" />}
              onClick={() => navigate(`/credencial/${user.id_driver}`)}
            />
          </TableCell>
          <TableCell align="center" component="th" scope="user">
            {user.data_driver.name + ' ' + user.data_driver.lastname}
          </TableCell>
          <TableCell align="center">{user.data_driver.email}</TableCell>
          <TableCell align="center">{user.data_driver.nlicencia}</TableCell>
          <TableCell align="center">{user.data_driver.modalidad}</TableCell>
          <TableCell align="center">{user.data_driver.club}</TableCell>
          <TableCell align="center">
            <BottomNavigationAction className="animate__animated animate__slideInRight"
              label="Editar"
              icon={<EditIcon fontSize="small" color="primary" />}
              onClick={() => navigate(`/form/${user.id_driver}`)}
            />
          </TableCell>
        </TableRow>
      )
    } else { 
      return (<div></div>)
    }
  }
  let Margin = {marginTop:'70px'}
  return (
    <TableContainer style={Margin} component = { Paper } >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">QR</TableCell>
            <TableCell align="center">Credencial</TableCell>
            <TableCell align="center">Nombre Piloto</TableCell>
            <TableCell align="center">Correo Electronico</TableCell>
            <TableCell align="center">Numero de Licencia</TableCell>
            <TableCell align="center">Modelo de Moto</TableCell>
            <TableCell align="center">Club</TableCell>
            {/* <TableCell align="center">Editar</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
              rowTable(user)
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default BasicTable