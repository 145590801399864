import { React, useEffect, useState } from "react";

import { Form, Formik } from "formik";
import {useParams } from "react-router-dom";
import * as Yup from "yup";

import { Container, Grid, Input, MenuItem, Typography } from "@mui/material";

import saveData, {
  UpdateUserById,
  usersApi,
} from "../api/userApi";
import ButtonEdit from "../components/Button/ButtonEdit";
import ButtonUI from "../components/Button/ButtonUI";
import DateUI from "../components/DatePicker/DateUI";
import SelectFormUI from "../components/Select/SelectFormUI";
import TextFieldUI from "../components/TextField/TextFieldUI";
import NavBar from "../components/Menu/navBar";

const FORM_VALIDATION = Yup.object().shape({
  name: Yup.string().required("Campo Requerido"),
  lastname: Yup.string().required("Campo Requerido"),
  email: Yup.string()
    .email("correo electronico invalido")
    .required("Campo Requerido"),
  celular: Yup.number()
    .integer()
    .typeError("Porfavor ingrese un numero de celular valido")
    .required("Campo Requerido"),
  dni: Yup.number()
    .integer()
    .typeError("Porfavor ingrese un documento de identidad valido")
    .required("Campo Requerido"),
  fechanac: Yup.date().required("Campo requerido"),
  tiposangre: Yup.string().required("Ingrese su Tipo de Sangre"),
  nlicencia: Yup.string().required("Campo requerido"),
  npiloto: Yup.string().required("Campo requerido"),
  club: Yup.string().required("Campo requerido"),
  licencia: Yup.string().required("Campo requerido"),
  participacion: Yup.string().required("Campo requerido"),
  modalidad: Yup.string().required("Campo requerido"),
});

const RegistroPiloto = () => {
  let { id_form } = useParams();

  const [user, setUser] = useState({});

  useEffect(() => {
    if (id_form === undefined) {
      console.log("No existe datos en el path");
    } else {
      getUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  const getUser = async () => {
    const request = await usersApi.get(
      "https://codesociety.site/driver.codesociety.dev/server/?list=" + id_form
    );
    var data_final = request.data.data[0];
    data_final.data_driver = JSON.parse(data_final.data_driver);
    setUser(data_final.data_driver);
  };

  const datos = {
    celular: "",
    club: "",
    dni: "",
    email: "",
    fechanac: "",
    file: null,
    lastname: "",
    licencia: "",
    modalidad: "",
    name: "",
    nlicencia: "",
    npiloto: "",
    participacion: "",
    tiposangre: "",
  };

  function INITIAL_FORM_STATE() {
    if (variable[0].name) {
      return user;
    }
    else { 
      return datos
    }
  }
  
  let variable = [
    {...user}
  ]
  console.log("variable",variable);
  function form(e) {
    if (id_form === undefined) {
      saveData(e);
      return  alert("Registro exitoso");
    } else {
      UpdateUserById(id_form, e);
      return alert("El usuario fue actualizado exitosamente");
    }
  }

  return (
    <div>
      <NavBar></NavBar>
      <Grid className="animate__animated animate__fadeIn" container>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Container maxWidth="md">
            <div className="registros">
              <Formik
                enableReinitialize={true}
                initialValues={INITIAL_FORM_STATE()}
                // initialValues={getUser()}
                validationSchema={FORM_VALIDATION}
                onSubmit={(values, { resetForm }) => {
                  form(values);
                  console.log("values:", values);
                  return resetForm({ values: datos });
                }}
              >
                {(formProps) => (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>Datos personales</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldUI name="name" label="Nombres"></TextFieldUI>
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldUI name="lastname" label="Apellidos" />
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldUI name="email" label="Correo Electronico" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldUI name="celular" label="Celular" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldUI
                          name="dni"
                          label="Documento de indentidad (DNI)"
                        />
                      </Grid>

                      <Grid item xs={8}>
                        <br />
                        <DateUI name="fechanac" label="Fecha de Nacimiento" />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>
                            {" "}
                            <br /> Datos de inscripcion
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldUI
                          name="nlicencia"
                          label="Numero de Licencia"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldUI name="npiloto" label="Numero de Piloto" />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectFormUI label="Licencia" name="licencia">
                          <MenuItem value="Anual">Anual</MenuItem>
                          <MenuItem value="Regional">Regional</MenuItem>
                        </SelectFormUI>
                      </Grid>
                      <Grid item xs={6}>
                        <SelectFormUI
                          label="Ingresa tu modalidad"
                          name="modalidad"
                        >
                          <MenuItem value="MOTOCROS">MOTOCROSS</MenuItem>
                          <MenuItem value="MINICROSS">MINICROSS</MenuItem>
                          <MenuItem value="SUPERCROSS">SUPERCROSS</MenuItem>
                          <MenuItem value="VELOCIDAD">VELOCIDAD</MenuItem>
                          <MenuItem value="ENDURO CROSS">ENDURO CROSS</MenuItem>
                          <MenuItem value="RALLY">RALLY</MenuItem>
                          <MenuItem value="HARD ENDURO">HARD ENDURO</MenuItem>
                          <MenuItem value="QUAD CROSS">QUAD CROSS</MenuItem>
                          <MenuItem value="UTV">UTV</MenuItem>
                          <MenuItem value="VELOCROSS">VELOCROSS</MenuItem>
                          <MenuItem value="CROSSKARTS">CROSSKARTS</MenuItem>
                          <MenuItem value="ARENA CROSS">ARENA CROSS</MenuItem>
                          <MenuItem value="TUNING OFF">TUNING OFF</MenuItem>
                        </SelectFormUI>
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldUI
                          name="club"
                          label="Club"
                          placeholder="Club al que perteneces"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectFormUI
                          label="Ingresa tu tipo de sangre"
                          name="tiposangre"
                        >
                          <MenuItem value="A+">A+</MenuItem>
                          <MenuItem value="A-">A-</MenuItem>
                          <MenuItem value="B+">B+</MenuItem>
                          <MenuItem value="B-">B-</MenuItem>
                          <MenuItem value="O+">O+</MenuItem>
                          <MenuItem value="O-">O-</MenuItem>
                          <MenuItem value="AB-">AB+</MenuItem>
                          <MenuItem value="AB-">AB-</MenuItem>
                        </SelectFormUI>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography>
                          <strong>
                            {" "}
                            <br />
                            Sube tu foto personal
                          </strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Input
                          className="fileInput"
                          type="file"
                          name="file"
                          onChange={(event) => {
                            formProps.setFieldValue(
                              "file",
                              event.target.files[0]
                            );
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item xs={12}>
                          <TextFieldUI
                            name="participacion"
                            label="Historial deportivo"
                            multiline
                          />
                        </Grid>
                        <br />
                        <br />
                        {id_form ? (
                          <ButtonEdit></ButtonEdit>
                        ) : (
                          <ButtonUI></ButtonUI>
                        )}
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </div>
          </Container>
        </Grid>
      </Grid>
    </div>
  );
};

export default RegistroPiloto;
