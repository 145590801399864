import axios from 'axios';

export const usersApi = axios.create();

export default async function saveData(datos) {

	try {
		console.log(`datos reales 100% no feik`, datos);
		const formData = new FormData();
		formData.append("_img", datos.file);
		// formData.append("id_driver", 1);
		formData.append("data_driver",JSON.stringify(datos));
		const response = axios.post("https://codesociety.site/driver.codesociety.dev/server/",
			formData, {
				headers: {
					"Content-Type": "multipart/form-data",
				},
			});
		console.log(`response 123`, response);
	} catch (err) {
		console.error(err);
	}
}

export const UpdateUserById = async (id,datos) => {
	try {
  console.log("Se recupero la informacion para editar correctamente", id);
  const formData = new FormData();
  formData.append("_img", datos.file);
  formData.append("id_driver", id);
  formData.append("data_driver", JSON.stringify(datos));
  const response = axios.post(
    "https://codesociety.site/driver.codesociety.dev/server/",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  console.log(`response 123`, response);
} catch (err) {
  console.error(err);
}
}

export const getUserById = async (id) => {
  console.log("Se recupero la informacion para editar correctamente", id);
  // try {
  // 	const request = await usersApi.get(
  // 		`https://codesociety.site/driver.codesociety.dev/server/?list=${id}`
  // 	);
  // 	console.log(`request`, request);
  // 	return request.data;
  // }
  // catch (err) {
  // 	console.log(`err`, err);
  // }
};