import React from 'react';

import { useFormikContext } from 'formik';

import Button from '@mui/material/Button';

const ButtonUI = (...props) => {

  const { submitForm } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  }

  const configButtonUI = {
    ...props,
    variant: 'contained',
    fullWidth: true,
    onClick: handleSubmit
  }
  return(
    <Button {...configButtonUI}>
     Registrar
    </Button>
  )
}

export default ButtonUI