import { useField } from 'formik';

import { TextField } from '@mui/material';

const DateUI = (name, ...props) => {

  const [field, meta] = useField(name);

  const configDateUI = {
    ...props,
    ...field,
    type: "date",
    fullWidth: true,
    label: 'Fecha de Nacimiento',
    variant: "standard",
    InputLabelProps:{
      shrink: true
    }
  }

  if (meta && meta.touched && meta.error ) { 
    configDateUI.error= true;
      configDateUI.helperText = meta.error;
  }

  return (
    <TextField
      {...configDateUI}
    />
      
  )

}


export default DateUI
